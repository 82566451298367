import React from "react";

import {
  CloseOutlined,
  MinusCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { REQUEST_STATUS, SolutionType } from "@src/constants";
import {
  clearNextStepModalState,
  setNextStepModalVisible,
  setProposedSolutionType,
} from "@src/services/nextStepModalSlice";
import {
  setActiveTabKey,
  setDetailsModalVisible,
} from "@src/services/requestOverviewSlice";
import { RootState, useAppDispatch, useAppSelector } from "@src/setupStore";
import { Employee, RequestOverviewDetails } from "@src/types";
import { Button } from "antd";
import "./staffing_request_modal_action_handlers.less";
import Cookies from "universal-cookie";

import { NextStepModal } from "../next_step_modal";

const cookies = new Cookies();

interface StaffingRequestModalActionHandlersProps {
  request: RequestOverviewDetails;
  onSend?: () => void;
}

interface AddButtonProps {
  key: string;
  text: string;
  icon?: React.ReactNode;
  className?: string;
  solutionType?: SolutionType;
  onClick?: () => void;
  disabled?: boolean;
  type?: "primary" | "default";
}

const statusMap: { [key: string]: string } = {
  Requested: REQUEST_STATUS.REQUESTED,
  "In recruitment": REQUEST_STATUS.IN_RECRUITMENT,
  "In progress": REQUEST_STATUS.IN_PROGRESS,
  Confirmed: REQUEST_STATUS.CONFIRMED,
  Proposed: REQUEST_STATUS.PROPOSED,
};

const StaffingRequestModalActionHandlers: React.FC<
  StaffingRequestModalActionHandlersProps
> = ({ request }) => {
  const dispatch = useAppDispatch();

  const { proposedSolutionType, nextStepModalVisible } = useAppSelector(
    (state: RootState) => state.nextStepModalSlice
  );

  const loggedInUser: Employee = cookies.get("loggedInuser");
  const isRequestOutdated = request?.isOutdated;

  /**
   * Function to handle the close event of the modal.
   * It will close the modal and reset the active tab key and clear the next step modal state.
   */
  function handleClose() {
    dispatch(setDetailsModalVisible(false));
    dispatch(setActiveTabKey("0"));
    dispatch(clearNextStepModalState());
  }

  function addButton({
    key,
    text,
    icon,
    className,
    solutionType,
    onClick,
    disabled,
    type = "primary",
  }: AddButtonProps) {
    return (
      <Button
        key={key}
        type={type}
        size="large"
        icon={icon}
        className={className}
        disabled={disabled}
        data-testid={key}
        onClick={() => {
          if (solutionType) {
            dispatch(setProposedSolutionType(solutionType));
          }
          if (onClick) {
            onClick();
            return;
          }
          dispatch(setNextStepModalVisible(true));
        }}
      >
        {text}
      </Button>
    );
  }

  function getActionsForRequest(): React.ReactNode[] {
    if (!request) return [];

    const commonActions: React.ReactNode[] = [
      addButton({
        key: "close-request-details-button",
        text: "Cancel",
        type: "default",
        icon: <CloseOutlined />,
        onClick: handleClose,
      }),
    ];

    const actions: React.ReactNode[] = [...commonActions];

    const statusActions: Record<string, React.ReactNode[]> = {
      [REQUEST_STATUS.REQUESTED]: [
        addButton({
          key: "next-step",
          text: "Next Step",
          icon: <RightOutlined />,
          disabled: !proposedSolutionType,
        }),
      ],
      [REQUEST_STATUS.IN_PROGRESS]: [
        addButton({
          key: "next-step",
          text: "Next Step",
          icon: <RightOutlined />,
          disabled: !proposedSolutionType,
        }),
      ],
      [REQUEST_STATUS.IN_RECRUITMENT]: [
        addButton({
          key: "removeFromRecruitment",
          text: "Remove from Recruitment List",
          className: "warning-button",
          solutionType: SolutionType.REMOVE_IN_RECRUITMENT,
        }),
      ],
      [REQUEST_STATUS.CONFIRMED]: [
        addButton({
          key: "take-back",
          text: "Take Back Proposal",
          icon: <MinusCircleOutlined />,
          className: "warning-button",
          solutionType: SolutionType.TAKE_BACK_PROPOSAL,
        }),
      ],
      [REQUEST_STATUS.PROPOSED]: [
        addButton({
          key: "take-back",
          text: "Take Back Proposal",
          icon: <MinusCircleOutlined />,
          className: "warning-button",
          solutionType: SolutionType.TAKE_BACK_PROPOSAL,
        }),
      ],
    };

    const actionList = statusActions[statusMap[request.status]];
    if (actionList && !isRequestOutdated) actions.push(...actionList);

    return actions;
  }

  return (
    <>
      {getActionsForRequest()}
      {nextStepModalVisible && (
        <NextStepModal request={request} loggedInUser={loggedInUser} />
      )}
    </>
  );
};

export default StaffingRequestModalActionHandlers;
