import React from "react";

import { AG_GRID_PINNED_TYPE } from "@src/constants";
import { RadioButtonRenderer } from "@src/custom_renderer/radio_button_renderer";
import { EmployeeNameRenderer } from "@src/features/staffing_request_details/custom_renderer/employee_name_renderer";
import { ProposeAllocationRenderer } from "@src/features/staffing_request_details/custom_renderer/propose_allocation_renderer";
import {
  AllocationDetails,
  FreeCapacity,
  RequestOverviewDetails,
} from "@src/types";
import { FrameworkComponents } from "@src/types/aggrid_types";
import { columnTypes, paginationGridOptions } from "@src/utils/aggrid_utils";
import { getAllocationColumnDefs } from "@src/utils/allocation_column_def_utils";
import {
  CellClassParams,
  ColDef,
  GridOptions,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import dayjs from "dayjs";

/**
 * Propose team member components
 * @returns {FrameworkComponents} The framework components
 */
function proposeTeamMemberComponents(): FrameworkComponents {
  return {
    nameCellRenderer: (props: CustomCellRendererProps) => {
      return <EmployeeNameRenderer props={props} />;
    },
    radioButtonRenderer: (props: CustomCellRendererProps) => {
      return <RadioButtonRenderer params={props} />;
    },
  };
}

/**
 * Get the column definitions for the grid
 *
 * @param requestEndDate    The request end date
 * @returns {ColDef[]} The column definitions
 */
function getColumnDefs(requestEndDate: Date): ColDef[] {
  return [
    {
      width: 10,
      pinned: "left",
      cellRendererSelector: (props: CustomCellRendererProps) =>
        props.node.rowPinned === AG_GRID_PINNED_TYPE.TOP
          ? { component: () => <div>Requested Workload</div> }
          : { component: "radioButtonRenderer" },
      colSpan: (params: CellClassParams) => {
        return params.node.rowPinned && 2;
      },
    },
    {
      field: "employeeName",
      headerName: "",
      pinned: "left",
      width: 200,
      cellRenderer: "nameCellRenderer",
    },
    ...getAllocationColumnDefs(new Date(), requestEndDate, getColDefMonth),
  ];
}

function getColDefMonth(year: number, month: number): ColDef {
  return {
    valueGetter: (params: ValueGetterParams) =>
      customValueGetter(params, year, month),
    valueFormatter: (params: ValueFormatterParams) => {
      const value = params.value || 0;
      return `${value}%`;
    },
  };
}

/**
 * Get the custom value getter
 *
 * @param {ValueGetterParams} params The value getter parameters
 * @param {number} year The year
 * @param {number} month The month
 *
 * @returns {number} The custom value
 */
export function customValueGetter(
  params: ValueGetterParams,
  year: number,
  month: number
): number {
  if (params.node.rowPinned === AG_GRID_PINNED_TYPE.TOP) {
    return getRequiredPercentage(params, year, month);
  }

  return getFreeCapacity(params, year, month);
}

/**
 * Get the required percentage
 *
 * @param params    The value getter parameters
 * @param year      The year  to get the required percentage for
 * @param month     The month to get the required percentage for
 */
function getRequiredPercentage(
  params: ValueGetterParams,
  year: number,
  month: number
): number {
  const foundAllocation: AllocationDetails = params.data.find(
    (allocation: AllocationDetails) =>
      dayjs(allocation.date).month() === month &&
      dayjs(allocation.date).year() === year
  );
  return foundAllocation?.requiredPercentage || 0;
}

/**
 * Get the free capacity
 *
 * @param params    The value getter parameters
 * @param year      The year to create the free capacity for
 * @param month     The month to create the free capacity for
 */
function getFreeCapacity(
  params: ValueGetterParams,
  year: number,
  month: number
): number {
  const currentFreeCapacities: FreeCapacity =
    params.data.freeCapacitiesDTOS?.find(
      (allocation: FreeCapacity) =>
        allocation.year === year && allocation.month === month + 1
    );
  return currentFreeCapacities?.capacity || 0;
}

/**
 * Render the propose time cell
 *
 * @param {CustomCellRendererProps} params The cell renderer parameters
 * @param {Date} requestEndDate The request end date
 *
 * @returns {React.ReactElement} The rendered propose time cell
 */
function getProposeAllocationRenderer(
  params: CustomCellRendererProps,
  requestEndDate: Date
): React.ReactElement {
  return (
    <ProposeAllocationRenderer
      params={params}
      requestEndDate={requestEndDate}
    />
  );
}

/**
 * Get the end date of the request
 *
 * @param request The request to get the end date from
 * @returns {Date} The end date of the request
 */
function getRequestEndDate(request: RequestOverviewDetails): Date {
  return new Date(
    request.roleAllocationDetails[
      request.roleAllocationDetails.length - 1
    ]?.date
  );
}

/**
 * Get the grid options
 *
 * @param request The request to get the grid options for
 * @returns {GridOptions} The grid options
 */
export function getGridOptions(request: RequestOverviewDetails): GridOptions {
  const requestEndDate: Date = getRequestEndDate(request);

  return {
    columnDefs: getColumnDefs(requestEndDate),
    components: proposeTeamMemberComponents(),
    detailCellRenderer: (props: CustomCellRendererProps) =>
      getProposeAllocationRenderer(props, getRequestEndDate(request)),
    editType: "fullRow",
    suppressContextMenu: true,
    masterDetail: true,
    embedFullWidthRows: true,
    detailRowHeight: 40,
    groupHeaderHeight: 26,
    animateRows: false,
    headerHeight: 26,
    ...paginationGridOptions,
    paginationPageSize: 4,
    defaultColDef: {
      resizable: false,
      sortable: false,
      ...columnTypes.default,
    },
  };
}
