import React from "react";

import { ROLES } from "@src/constants";
import { WorkloadInformationRenderer } from "@src/custom_renderer/workload_information_renderer";
import { FrameworkComponents } from "@src/types/aggrid_types";
import { Allocation } from "@src/types/role_request_types";
import {
  columnTypes,
  preventPinnedColumnJumpingGridOption,
} from "@src/utils/aggrid_utils";
import { getAllocationColumnDefs } from "@src/utils/allocation_column_def_utils";
import {
  CellClassParams,
  ColDef,
  GridOptions,
  ITooltipParams,
  ValueGetterParams,
} from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import dayjs from "dayjs";

export function getGridOptions(): GridOptions {
  return {
    ...preventPinnedColumnJumpingGridOption,
    rowHeight: 50,
    tooltipShowDelay: 0,
    domLayout: "autoHeight",
    suppressContextMenu: true,
    groupHeaderHeight: 26,
    headerHeight: 26,
    components: getComponents(),
    defaultColDef: {
      ...columnTypes.default,
      resizable: false,
      sortable: false,
    },
    context: {
      view: ROLES.TEAM_LEAD,
    },
  };
}

function getComponents(): FrameworkComponents {
  return {
    workloadInformationRenderer: (props: CustomCellRendererProps) => {
      return <WorkloadInformationRenderer props={props} />;
    },
  };
}

export function getColumnDefs(
  workloadStartDate: Date,
  workloadEndDate: Date
): ColDef[] {
  const startDate = dayjs().isBefore(workloadStartDate)
    ? workloadStartDate
    : new Date();

  const allocationColumns: ColDef[] = getAllocationColumnDefs(
    startDate,
    workloadEndDate,
    getColDefMonth
  );
  return [
    {
      field: "scope",
      cellRenderer: "workloadInformationRenderer",
      headerName: "",
      pinned: "left",
      width: 320,
    },
    ...allocationColumns,
  ];
}

export function getColDefMonth(year: number, month: number): ColDef {
  const findMatchingAllocation = (allocations: Allocation[]) =>
    allocations.find(
      (allocation: Allocation) =>
        dayjs(allocation.date).year() === year &&
        dayjs(allocation.date).month() === month
    );

  return {
    cellClassRules: {
      "allocation-mismatch": (params: CellClassParams) => {
        const matchingAllocation = findMatchingAllocation(
          params.data.allocations
        );
        return (
          matchingAllocation?.proposedPercentage !== undefined &&
          !matchingAllocation?.proposalMatches
        );
      },
      "allocation-modified": (params: CellClassParams) => {
        const matchingAllocation = findMatchingAllocation(
          params.data.allocations
        );
        return (
          matchingAllocation?.newRequiredPercentage !== undefined &&
          matchingAllocation?.newRequiredPercentage !==
            matchingAllocation?.requiredPercentage
        );
      },
    },
    tooltipValueGetter: (params: ITooltipParams) => {
      const matchingAllocation = findMatchingAllocation(
        params.data.allocations
      );
      if (!matchingAllocation?.newRequiredPercentage) return null;
      return `Changed from ${matchingAllocation?.requiredPercentage}% to ${matchingAllocation?.newRequiredPercentage}%`;
    },
    valueGetter: (params: ValueGetterParams) => {
      const matchingAllocation = findMatchingAllocation(
        params.data.allocations
      );
      return (
        matchingAllocation?.proposedPercentage ||
        matchingAllocation?.newRequiredPercentage ||
        matchingAllocation?.requiredPercentage ||
        0
      );
    },
  };
}
