import React, { useCallback, useMemo, useRef, useState } from "react";

import "./propose_team_member_overview_table.less";
import { getGridOptions } from "@src/features/staffing_request_details/utils/propose_team_member_overview_table_utils";
import {
  setConfirmationButtonDisableStatus,
  setProposedTeamMemberId,
} from "@src/services/nextStepModalSlice";
import { useGetTeamCapacitiesQuery } from "@src/services/slices/teamLeadsApi";
import { RequestOverviewDetails, TeamMemberCapacity } from "@src/types";
import {
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  IRowNode,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useDispatch } from "react-redux";

interface ProposeTeamMemberProps {
  request: RequestOverviewDetails;
}

const ProposeTeamMemberOverviewTable: React.FC<ProposeTeamMemberProps> = ({
  request,
}) => {
  const gridApi = useRef<GridApi | null>(null);
  const dispatch = useDispatch();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number>(null);

  const { data } = useGetTeamCapacitiesQuery({
    roleRequestId: request.projectRoleRequestId,
  });

  const onGridReady = useCallback((params: GridReadyEvent) => {
    gridApi.current = params.api;
  }, []);

  const pinnedTopRowData = useMemo<any[]>(() => {
    const request: RequestOverviewDetails = getCurrentRequest();
    return [request.roleAllocationDetails];
  }, []);

  /**
   * Callback function to handle the selection change event
   * It expands the newly selected user and collapses the previously selected user
   */
  function onSelectionChanged() {
    const selectedRows: TeamMemberCapacity[] =
      gridApi.current.getSelectedRows();

    // Collapse the previously selected user
    if (selectedEmployeeId) {
      const rowNode: IRowNode = gridApi.current.getRowNode(
        selectedEmployeeId.toString()
      );
      rowNode.setExpanded(false);
    }

    // Expand the newly selected user
    const rowNode: IRowNode = gridApi.current.getRowNode(
      selectedRows[0].employeeId.toString()
    );
    rowNode.setExpanded(true);

    // Update the selected users state
    setSelectedEmployeeId(selectedRows[0].employeeId);
    dispatch(setProposedTeamMemberId(selectedRows[0].employeeId));
    dispatch(setConfirmationButtonDisableStatus(false));
  }

  function getCurrentRequest(): RequestOverviewDetails {
    return request?.childRequest ? request.childRequest : request;
  }

  return (
    <div className="header-white propose-team-member-overview-table ag-theme-alpine">
      <AgGridReact
        rowData={data}
        gridOptions={getGridOptions(getCurrentRequest())}
        getRowId={(params: GetRowIdParams) => {
          return params.data.employeeId;
        }}
        onSelectionChanged={onSelectionChanged}
        pinnedTopRowData={pinnedTopRowData}
        onGridReady={onGridReady}
      />
    </div>
  );
};

export default ProposeTeamMemberOverviewTable;
