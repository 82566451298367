import React from "react";

import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { SolutionType } from "@src/constants";
import { ForwardSelection } from "@src/features/staffing_request_details/components/forward_selection";
import { ProposeTeamMemberOverviewTable } from "@src/features/staffing_request_details/components/propose_team_member_overview_table";
import { VacancySelection } from "@src/features/staffing_request_details/components/vacancy_selection";
import {
  clearNextStepModalState,
  resetSolutionState,
  setNextStepModalVisible,
} from "@src/services/nextStepModalSlice";
import { setDetailsModalVisible } from "@src/services/requestOverviewSlice";
import { useAddNoteMutation } from "@src/services/slices/notesApiSlice";
import {
  useForwardRequestMutation,
  useProposeTeamMemberMutation,
  useSuggestDifferentWorkloadMutation,
  useTakeBackProposalMutation,
  useTeamLeadDeclineRequestMutation,
  useUpdateInRecruitmentMutation,
} from "@src/services/slices/teamLeadsApi";
import { RootState, useAppDispatch, useAppSelector } from "@src/setupStore";
import { Employee, Note, RequestOverviewDetails } from "@src/types";
import { getInitials } from "@src/utils/helper";
import { App, Avatar, Form, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import "./next_step_modal.less";

interface NextStepModalProps {
  request: RequestOverviewDetails;
  loggedInUser: Employee;
}

const NextStepModal: React.FC<NextStepModalProps> = ({
  request,
  loggedInUser,
}) => {
  const [form] = Form.useForm<{ comment: string }>();

  const { message } = App.useApp();

  const {
    nextStepModalVisible,
    confirmationButtonDisabled,
    proposedSolutionType,
    proposeTeamMember,
    selectedVacancy,
    forward,
  } = useAppSelector((state: RootState) => state.nextStepModalSlice);

  //local states

  const dispatch = useAppDispatch();

  //hooks
  const [forwardRequest] = useForwardRequestMutation();
  const [addNote] = useAddNoteMutation();
  const [proposeNewTeamMember] = useProposeTeamMemberMutation();
  const [updateInRecruitment] = useUpdateInRecruitmentMutation();
  const [declineRequest] = useTeamLeadDeclineRequestMutation();
  const [takeBackProposal] = useTakeBackProposalMutation();
  const [suggestDifferentWorkload] = useSuggestDifferentWorkloadMutation();

  //local variables
  const requestTitle = `${request.projectRoleRequestId} - ${request.genericRole}`;

  /**
   * Handles the cancellation of the next step Modal.
   */
  function onCancelNextStepModal() {
    dispatch(setNextStepModalVisible(false));
    dispatch(resetSolutionState());
  }

  /**
   * Handles the confirmation of the next step Modal and call the appropriate function based on the selected solution.
   *
   */
  async function onConfirmSend() {
    const note: string = form.getFieldValue("comment");

    switch (proposedSolutionType) {
      case SolutionType.PROPOSE_TEAM_MEMBER:
        !proposeTeamMember?.proposedAllocations?.length
          ? await handleProposeTeamMember()
          : await handleProposeTeamMemberWithNewAllocation();
        break;
      case SolutionType.VACANCY:
        await handleProposeVacancyConfirmation();
        break;
      case SolutionType.FORWARD:
        await handleForwardRequestConfirmation();
        break;
      case SolutionType.IN_RECRUITMENT:
        await handleInRecruitmentConfirmation(true);
        break;
      case SolutionType.REMOVE_IN_RECRUITMENT:
        await handleInRecruitmentConfirmation(false);
        break;
      case SolutionType.TAKE_BACK_PROPOSAL:
        await handleTakeBackProposal();
        break;
      case SolutionType.DECLINE:
        await handleDeclineRequestConfirmation();
        break;
      default:
        break;
    }

    if (note) await createNote(note);
    dispatch(setDetailsModalVisible(false));
    dispatch(setNextStepModalVisible(false));
    dispatch(clearNextStepModalState());
  }

  /**
   * Handles the proposed vacancy solution. This will propose a vacancy to cover the project.
   *
   */
  async function handleProposeVacancyConfirmation() {
    try {
      await proposeNewTeamMember({
        roleRequestId: request.projectRoleRequestId,
        teamMemberId: selectedVacancy.selectedVacancyId,
      }).unwrap();
      message.success("Vacancy selected successfully");
    } catch (error) {
      console.log(error);
    }
  }

  /**
   *  Handles the forward request solution. This will forward the request to another team lead.
   *
   */
  async function handleForwardRequestConfirmation() {
    try {
      await forwardRequest({
        roleRequestId: request.projectRoleRequestId,
        teamLeadId: forward.forwardedTeamLeadId,
      }).unwrap();
      message.success("Request forwarded successfully");
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Handles the marking or removing in recruitment solution. This will mark the request as in recruitment.
   *
   */

  async function handleInRecruitmentConfirmation(inRecruitment: boolean) {
    try {
      await updateInRecruitment({
        roleRequestId: request.projectRoleRequestId,
        inRecruitment: inRecruitment,
      }).unwrap();
      const operationText = inRecruitment ? `marked as` : `removed from`;
      message.success(`Request ${operationText} in recruitment successfully`);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Handles the take back proposal solution. This will take back the proposal.
   */
  async function handleTakeBackProposal() {
    try {
      await takeBackProposal({
        roleRequestId: request.projectRoleRequestId,
      }).unwrap();
      message.success("Proposal taken back successfully");
    } catch (error) {
      console.error("Failed to take back proposal", error);
    }
  }

  /**
   * Handles the decline request solution. This will decline the request and propose a different workload.
   *
   */
  async function handleProposeTeamMember() {
    try {
      await proposeNewTeamMember({
        roleRequestId: request.projectRoleRequestId,
        teamMemberId: proposeTeamMember.selectedTeamMemberId,
      }).unwrap();
      message.success("selected team member successfully proposed");
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Handle proposeNewTeamMember with new allocation. This will propose a different workload for the selected team member.
   */
  async function handleProposeTeamMemberWithNewAllocation() {
    try {
      await suggestDifferentWorkload({
        id: request.projectRoleRequestId,
        selectedMemberId: proposeTeamMember.selectedTeamMemberId,
        requestAllocationPayload: proposeTeamMember.proposedAllocations,
      }).unwrap();
      message.success(
        "selected team member and allocation successfully proposed"
      );
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Handle the decline request solution. This will decline the request and propose a different workload.
   * The propose workload will be implemented in another ticket.
   *
   */
  async function handleDeclineRequestConfirmation() {
    try {
      await declineRequest({
        payLoad: {
          id: request.projectRoleRequestId,
          requestAllocationPayload: [],
        },
        params: { declineRequestType: "propose" },
      }).unwrap();
      message.success("Request declined successfully");
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Creates a note and save it.
   *
   * @param noteText  The note text to be saved.
   */
  async function createNote(noteText: string) {
    const note: Note = {
      employeeId: loggedInUser.employeeId,
      createdDate: new Date().toISOString(),
      roleRequestId: request.projectRoleRequestId,
      text: noteText,
      employeeFullName: loggedInUser.fullName,
      createdBy: loggedInUser.employeeId,
    };

    addNote(note)
      .unwrap()
      .then(() => {
        message.success("Successfully saved note", 2);
      })
      .catch((err) => console.log("error", err));
  }

  /**
   * Generates the children for the next step modal based on the selected solution.
   */
  function generateChildren() {
    switch (proposedSolutionType) {
      case SolutionType.PROPOSE_TEAM_MEMBER:
        return <ProposeTeamMemberOverviewTable request={request} />;
      case SolutionType.VACANCY:
        return <VacancySelection />;
      case SolutionType.FORWARD:
        return <ForwardSelection />;
      case SolutionType.IN_RECRUITMENT:
        return (
          <p>{`Are you sure you want to mark ${requestTitle} as in recruitment.`}</p>
        );
      case SolutionType.REMOVE_IN_RECRUITMENT:
        return (
          <p>{`This action will change the request status back to 'Requested'. Are you sure you want to remove ${requestTitle} from the recruitment list?`}</p>
        );
      case SolutionType.TAKE_BACK_PROPOSAL:
        return (
          <>
            <p>
              Are you sure you want to take back{" "}
              <span className="bold">{request.assignedTeamMember}?</span>
            </p>
            <p>
              {" "}
              Leave a comment for the project lead{" "}
              <span className="bold">{request.projectLead}</span>.
            </p>
          </>
        );
      case SolutionType.DECLINE:
        return null;
      case SolutionType.DECLINE_AND_PROPOSE:
        return null; //will be implemented in another ticket
      default:
        break;
    }
  }

  /**
   * Generates the title for the next step modal based on the selected solution.
   */
  function generateTitle() {
    switch (proposedSolutionType) {
      case SolutionType.PROPOSE_TEAM_MEMBER: {
        return `Propose team member(s) for ${requestTitle}`;
      }
      case SolutionType.VACANCY: {
        return `Select Vacancy for ${requestTitle}`;
      }
      case SolutionType.FORWARD:
        return `Forward request ${requestTitle}`;
      case SolutionType.IN_RECRUITMENT:
        return `Mark '${requestTitle}' as in recruitment`;
      case SolutionType.REMOVE_IN_RECRUITMENT:
        return `Remove '${requestTitle}' from in recruitment`;
      case SolutionType.TAKE_BACK_PROPOSAL:
        return `Take back proposal`;
      case SolutionType.DECLINE:
        return `Decline ${requestTitle}`;
      case SolutionType.DECLINE_AND_PROPOSE:
        return `Decline ${requestTitle}`; //the component will be implemented in another ticket
      default:
        break;
    }
  }

  return (
    <Modal
      open={nextStepModalVisible}
      centered
      width={1018}
      maskClosable={false}
      closable={false}
      title={generateTitle()}
      okButtonProps={{
        icon: <CheckOutlined />,
        size: "large",
        disabled: confirmationButtonDisabled,
        "data-testid": "confirm-button",
      }}
      onOk={() => onConfirmSend()}
      okText="Confirm"
      cancelButtonProps={{
        icon: <CloseOutlined />,
        size: "large",
        "data-testid": "close-next-step-modal",
      }}
      onCancel={onCancelNextStepModal}
      className="next-step-modal"
    >
      {generateChildren()}
      <div className="next-step-modal__comment-section">
        <Avatar className="next-step-modal__comment-box-avatar">
          {getInitials(loggedInUser.fullName)}
        </Avatar>
        <Form layout="vertical" form={form}>
          <Form.Item label="Add Comment" name="comment">
            <TextArea
              autoSize={false}
              rows={4}
              style={{ resize: "none" }}
              data-testid="comment-input"
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default NextStepModal;
