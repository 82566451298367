import { NumericEditorRenderer } from "@src/custom_renderer/numeric_editor_renderer";
import { AllocationDetails, NumericRendererUpdateState } from "@src/types";
import { getAllocationColumnDefs } from "@src/utils/allocation_column_def_utils";
import {
  ColDef,
  GridOptions,
  SuppressKeyboardEventParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { CustomCellEditorProps } from "ag-grid-react";
import dayjs from "dayjs";

let cellValueChange: (updateState: NumericRendererUpdateState) => void;

export function getProposeTeamMemberTimeRendererGridOptions(
  requestEndDate: Date,
  cellValueChangedParam: (updateState: NumericRendererUpdateState) => void
): GridOptions {
  cellValueChange = cellValueChangedParam;

  return {
    columnDefs: [
      ...getAllocationColumnDefs(new Date(), requestEndDate, getColDefMonth),
    ],
    editType: "fullRow",
    rowHeight: 40,
    suppressContextMenu: true,
    domLayout: "autoHeight",
  };
}

function customValueGetter(
  params: ValueGetterParams,
  year: number,
  month: number
): number {
  const matchesYearMonth = (allocation: AllocationDetails) =>
    dayjs(allocation.date).year() === year &&
    dayjs(allocation.date).month() === month;

  const foundChildAllocation: AllocationDetails =
    params.data.childRequest?.roleAllocationDetails.find(matchesYearMonth);
  const foundAllocation: AllocationDetails =
    params.data.roleAllocationDetails.find(matchesYearMonth);

  return (
    foundChildAllocation?.requiredPercentage ||
    foundAllocation?.requiredPercentage ||
    0
  );
}

function getColDefMonth(year: number, month: number): ColDef {
  return {
    editable: true,
    valueGetter: (params: ValueGetterParams) =>
      customValueGetter(params, year, month),
    cellEditor: (params: CustomCellEditorProps) => {
      return (
        <NumericEditorRenderer
          params={params}
          cellValueChange={cellValueChange}
        />
      );
    },
    valueFormatter: (params: ValueFormatterParams) => {
      const value = params.value || 0;
      return `${value}%`;
    },
    suppressKeyboardEvent: (params: SuppressKeyboardEventParams) =>
      params.event.key === "Enter",
  };
}
