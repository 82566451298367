import React, { useMemo, useRef } from "react";

import { NoRowsOverlay } from "@src/components/overlays/no_rows_overlay";
import { REQUEST_STATUS } from "@src/constants";
import {
  getColumnDefs,
  getGridOptions,
} from "@src/features/staffing_request_details/utils/staffing_request_workload_table_grid_options";
import {
  getEndDate,
  getRowData,
} from "@src/features/staffing_request_details/utils/staffing_request_workload_table_utils";
import { AllocationDetails, RequestOverviewDetails } from "@src/types";
import { ColDef, GridReadyEvent } from "ag-grid-community";
import type { ColumnVisibleEvent } from "ag-grid-community/dist/types/core/events";
import { AgGridReact } from "ag-grid-react";
import dayjs from "dayjs";
import _ from "lodash";

interface StaffingRequestWorkloadTableProps {
  request: RequestOverviewDetails;
}

const StaffingRequestWorkloadTable: React.FC<
  StaffingRequestWorkloadTableProps
> = ({ request }) => {
  const gridApi = useRef(null);
  const projectEndDate: Date = getEndDate(request.yearlyAllocations);
  const isRequested: boolean = [
    REQUEST_STATUS.REQUESTED,
    REQUEST_STATUS.IN_PROGRESS,
  ].includes(request.status);

  function getWorkloadDates(request: RequestOverviewDetails): {
    startDate: Date | null;
    endDate: Date | null;
  } {
    const getFirstDate = (details: AllocationDetails[]) =>
      details?.length > 0 ? details[0].date : null;
    const getLastDate = (details: AllocationDetails[]) =>
      details?.length > 0 ? _.last(details).date : null;

    const childAllocationDetails: AllocationDetails[] =
      request?.childRequest?.roleAllocationDetails;
    const mainAllocationDetails: AllocationDetails[] =
      request.roleAllocationDetails;

    const workloadStartDate: string =
      getFirstDate(childAllocationDetails) ||
      getFirstDate(mainAllocationDetails);
    const workloadEndDate: string =
      getLastDate(childAllocationDetails) || getLastDate(mainAllocationDetails);

    return {
      startDate: workloadStartDate ? dayjs(workloadStartDate).toDate() : null,
      endDate: workloadEndDate ? dayjs(workloadEndDate).toDate() : null,
    };
  }

  const columnDefs = useMemo<ColDef[]>(() => {
    const { startDate, endDate } = getWorkloadDates(request);
    return getColumnDefs(startDate, endDate);
  }, [projectEndDate]);

  function onGridReady(event: GridReadyEvent): void {
    gridApi.current = event.api;
  }

  return (
    <div
      className="staffing-request-workload-table"
      data-testid="staffing-request-workload-table"
    >
      <div className="ag-theme-alpine">
        <AgGridReact
          rowData={getRowData(request, isRequested)}
          gridOptions={getGridOptions()}
          onGridReady={onGridReady}
          noRowsOverlayComponent={NoRowsOverlay}
          noRowsOverlayComponentParams={{
            text: "No requests found",
            customStyle: { marginTop: "48px" },
          }}
          onColumnVisible={(props: ColumnVisibleEvent) =>
            props.api.sizeColumnsToFit()
          }
          columnDefs={columnDefs}
        />
      </div>
    </div>
  );
};

export default React.memo(StaffingRequestWorkloadTable);
